function scrollToAnchor(anchor_id) {
  var tag = $("#" + anchor_id + "");
  $('html,body').animate({ scrollTop: tag.offset().top }, 'slow');
}


jQuery(function($) {
  var lastScrollTop = 0;
  // Add shrink class to site header after 50px
  var siteHeaderHeight = $(".site-header").height() ;
  $(document).on("scroll", function() {
    scrollTop = $(window).scrollTop();
    // if (scrollTop > lastScrollTop && $(document).scrollTop() > 0) {
    //     $('.site-header').addClass('hidden');
    // } else {
    //     $('.site-header').removeClass('hidden');
    // }
    lastScrollTop = scrollTop;

    if ($(document).scrollTop() > siteHeaderHeight) {
      //$(".site-header").addClass("shrink");
      $("#vertical-navigation").addClass("fixed-top");

    } else {
      // $(".site-header").removeClass("shrink");
      $("#vertical-navigation").removeClass("fixed-top");
    }

  });

});
