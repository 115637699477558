/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        $(".wpcf7-submit").on("click", function (e) {
          $(this).addClass("loading");
        });
        $(".wpcf7-submit").on("click", function (e) {
          $(this).addClass("loading");
        });

        $(".wpcf7").on(
          "wpcf7invalid wpcf7spam wpcf7mailsent wpcf7mailfailed",
          function () {
            $(this).find(".wpcf7-submit").removeClass("loading");
          }
        );

        autosize($("textarea"));

        //defer youtube loading
        function vidDefer() {
          var vidDefer = document.getElementsByTagName("iframe");
          for (var i = 0; i < vidDefer.length; i++) {
            if (vidDefer[i].getAttribute("data-src")) {
              vidDefer[i].setAttribute(
                "src",
                vidDefer[i].getAttribute("data-src")
              );
            }
          }
        }
        window.onload = vidDefer;
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        console.log("_JS_READY");
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
        // $('#hero-accueil').vide({
        //   mp4: '/wp-content/uploads/2015/04/Projet-1_21.mp4',
        //   webm: '/wp-content/uploads/2015/04/Projet-1_21.webm',
        //   ogv: '/wp-content/uploads/2015/04/Projet-1_21.ogv',
        //   poster: '/wp-content/uploads/2016/02/frame_accueil.jpg'
        // }, {
        //   loop: true,
        //   autoplay: true,
        //   position: '50% 50%', // Similar to the CSS `background-position` property.
        //   posterType: 'jpg', // Poster image type. "detect" — auto-detection; "none" — no poster; "jpg", "png", "gif",... - extensions.
        //   resizing: true, // Auto-resizing, read: https://github.com/VodkaBears/Vide#resizing
        //   bgColor: 'transparent', // Allow custom background-color for Vide div,
        // });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
    // fleet page
    fleet: {
      init: function () {},
      finalize: function () {},
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
